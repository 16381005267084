<template>
  <cui-acl  :roles="['HRseeUsersAll', 'HRseeUsersDepartment', 'HRseeUsersProject', 'HRseeUsersProjectDepartment']" redirect>
    <div class="kit__utils__heading">
      <h5>
        <span class="mr-3">{{ $route.params.status === 'active'  ? 'Ansatte' : $route.params.status === 'inactive' ? 'Inaktive' : 'Under Onboarding' }}</span>
        <a-button style="float: right;" @click="downloadData()">
          Last ned informasjon
        </a-button>
        <a-input placeholder="Søk..." @change="search" v-model="searchText" class="col-md-3" style="float: right;"/>
      </h5>
    </div>
    <a-table :columns="columns" :dataSource="data" @change="onChange">
      <a slot="action" slot-scope="text, record" @click="() => aboutUser(record.uid)"><i class="fe fe-edit"></i></a>
    </a-table>
  </cui-acl>
</template>
<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import firebase from '@/services/firebase'
import { mapState } from 'vuex'
import exportFromJSON from 'export-from-json'

function onChange(pagination, filters, sorter) {
  console.log('params', pagination, filters, sorter)
}

export default {
  name: 'TablesAntdFilterSorter',
  computed: {
    ...mapState(['user']),
  },
  components: {
    cuiAcl,
  },
  data() {
    return {
      searchText: '',
      data: [],
      oldData: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'employeeID',
          key: 'employeeID',
        },
        {
          title: 'Navn',
          dataIndex: 'fullName',
          key: 'fullName',
        },
        {
          title: 'Avdeling',
          dataIndex: 'department',
          key: 'department',
        },
        {
          title: 'Prosjekt',
          dataIndex: 'project',
          key: 'project',
        },
        {
          title: '',
          dataIndex: 'uid',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  watch: {
    '$route.params.status': {
      handler: function(status) {
        if (status === 'inactive' && !['HRseePreviousEmployment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
          this.$router.push({ path: '/auth/404' })
        }
        this.getData(status)
        this.searchText = ''
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onChange,
    aboutUser(uid) {
      console.log(uid)
      this.$router.push({ path: `/hr/person/${uid}` })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    downloadData() {
      const data = this.data
      const fileName = 'employeeData'
      const exportType = exportFromJSON.types.csv
      const delimiter = ';'
      exportFromJSON({ data, fileName, exportType, delimiter })
    },
    search(e) {
      const value = e.target.value
      this.data = this.oldData.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1)
    },
    getData(status) {
      this.data = []
      let ref = firebase.firestore.collection('users').where('status', '==', status).where('companies', 'array-contains-any', this.$store.state.user.companies)
      if (this.$store.state.user.companies.length > 1 && !this.columns.find(el => el.title === 'Company')) {
        this.columns.splice(this.columns.length - 2, 0, {
          title: 'Company',
          dataIndex: 'company',
          key: 'company',
        })
      }
      if (['HRseeUsersDepartment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
        ref = ref.where('department', '==', this.$store.state.user.department)
      } else if (['HRseeUsersProject'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
        console.log(this.$store.state.user.project, 'checking')
        ref = ref.where('project', '==', this.$store.state.user.project)
      } else if (['HRseeUsersProjectDepartment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
        ref = ref.where('department', '==', this.$store.state.user.department).where('project', '==', this.$store.state.user.project)
      }
      ref.orderBy('fullName', 'asc')
        .onSnapshot(snapshot => {
          let i = 0
          this.data = []
          snapshot.forEach(doc => {
            const positions = doc.data().positions
            const firstPosition = positions ? positions.length > 0 ? positions[0] : {} : {}
            const lastPosition = positions ? positions.length > 0 ? positions[positions.length - 1] : {} : {}
            const project = positions ? positions.length > 0 ? positions[positions.length - 1].project : '' : ''
            i += 1
            let person = {
              key: i,
              uid: doc.id,
              employeeID: String(doc.data().employeeID),
              fullName: doc.data().fullName,
              department: doc.data().department,
              company: doc.data().companies.join(),
              project: project,
              email: doc.data().email,
              phone: doc.data().phone,
              adress: doc.data().adress,
              zip: doc.data().zip,
              role: doc.data().role,
              city: doc.data().city,
              birthdate: doc.data().birthdate,
              firstName: doc.data().name,
              lastName: doc.data().surname,
              startDate: firstPosition.startDate,
              endDate: lastPosition.endDate,
            }
            if (['HRseeUsersSensitive'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
              // add following fields to person
              person = {
                ...person,
                personalNumber: doc.data().personalNumber,
                bankAccount: doc.data().bankAccount,
              }
            }
            this.data.push(person)
          })
          this.oldData = this.data
        })
    },
  },
}
</script>
